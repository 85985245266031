//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { documentBillAuditPage, documentBillIndexPage } from '@/api/modular/fileStream/documentManage'
import { PullRefresh } from 'vant'
import item from './item.vue'
export default {
    components: {
        item,
        PullRefresh,
    },
    data() {
        return {
            loading: false,
            finished: false,
            isRefresh: false,
            queryParam: { approvalTab: 1 },
            approvalTab: 1,
            pageNo: 1,
            billList: [],
            billAuditList: [],
            pageSize: 10,
            list: [],
        }
    },

    mounted() {
        this.getBillList()
    },
    methods: {
        onLoad() {
            this.loading = true
            this.pageNo++
            if (+this.approvalTab == 1) {
                this.getBillList()
            } else {
                this.getBillAuditList()
            }
        },
        onRefresh() {
            this.pageNo = 1
            this.pageSize = 10
            if (this.approvalTab == 1) {
                this.getBillList()
            } else {
                this.getBillAuditList()
            }
        },
        refresh({ approvalTab, name }) {
            this.pageNo = 1
            this.list = []
            this.pageSize = 10
            this.queryParam.name = name || ''
            this.approvalTab = approvalTab || ''
            if (approvalTab == 1) {
                this.getBillList()
            } else {
                this.getBillAuditList()
            }
        },
        getBillList() {
            const formatDraftId = (id) => {
                if (!id) return []
                return JSON.parse(id)
            }
            documentBillIndexPage(
                Object.assign(
                    {
                        pageNo: this.pageNo,
                        pageSize: this.pageSize,
                    },
                    this.queryParam
                )
            )
                .then((res) => {
                    const data = { ...res.data }
                    data.rows = data.rows.map((ele) => {
                        ele.draftId = formatDraftId(ele.draftId)
                        return ele
                    })
                    this.finished = data.rows && data.rows.length < this.pageSize
                    if (this.pageNo == 1) {
                        this.billList = [...data.rows]
                    } else {
                        this.billList = [...this.billList, ...data.rows]
                    }
                    this.$set(this, 'list', this.billList)
                    return
                    this.list = [
                        {
                            createTime: '2023-01-04 18:20:40.000',
                            createUser: null,
                            updateTime: null,
                            updateUser: null,
                            id: '1610581972659306498',
                            templateId: null,
                            name: '發文稿紙',
                            title: '梵蒂岡地方給對方',
                            agency: null,
                            documentNumber: '0000000001',
                            deviseOpinion: null,
                            handResult: null,
                            content: null,
                            summary: null,
                            userId: null,
                            corpId: null,
                            documentType: 3,
                            urgencyDegree: 3,
                            secrecyDegree: 4,
                            draftId:
                                '[{"userId":"woJe-yBwAANiPi5Md3xweTXo2qt2Q6Ww","corpId":"wpJe-yBwAAUnMGs7fstrz2mKEx7ql4Kw","type":"userName"}]',
                            draftCompany: null,
                            tellPhone: null,
                            serialNumber: null,
                            outDraftCompany: null,
                            outDraftDesc: null,
                            meetCompany: null,
                            meetDesc: null,
                            checkIds: null,
                            mainIds: null,
                            sendIds: null,
                            acceptTime: null,
                            approvalStatus: 2,
                            recipientStatus: null,
                            nooseStatus: null,
                            receiverCompany: null,
                            acceptStatus: 0,
                            approvalFlag: null,
                            status: null,
                            documentNodeList: null,
                            documentFileList: null,
                            mainFile: null,
                            approvalId: null,
                            nodeId: null,
                            acceptList: null,
                            templateName: null,
                        },
                        {
                            createTime: '2022-12-14 14:54:05.000',
                            createUser: null,
                            updateTime: null,
                            updateUser: null,
                            id: '1602919836751536130',
                            templateId: null,
                            name: '某某某單位發文稿紙',
                            title: '某某某某文件',
                            agency: null,
                            documentNumber: '202212141452',
                            deviseOpinion: null,
                            handResult: null,
                            content: null,
                            summary: null,
                            userId: null,
                            corpId: null,
                            documentType: 3,
                            urgencyDegree: 2,
                            secrecyDegree: 4,
                            draftId:
                                '[{"userId":"woJe-yBwAArHQYIhObVXpiGU-sKrnLlw","corpId":"wpJe-yBwAAUnMGs7fstrz2mKEx7ql4Kw","type":"userName"}]',
                            draftCompany: null,
                            tellPhone: null,
                            serialNumber: null,
                            outDraftCompany: null,
                            outDraftDesc: null,
                            meetCompany: null,
                            meetDesc: null,
                            checkIds: null,
                            mainIds: null,
                            sendIds: null,
                            acceptTime: null,
                            approvalStatus: 2,
                            recipientStatus: null,
                            nooseStatus: null,
                            receiverCompany: null,
                            acceptStatus: 0,
                            approvalFlag: null,
                            status: null,
                            documentNodeList: null,
                            documentFileList: null,
                            mainFile: null,
                            approvalId: null,
                            nodeId: null,
                            acceptList: null,
                            templateName: null,
                        },
                        {
                            createTime: '2022-12-14 14:43:09.000',
                            createUser: null,
                            updateTime: null,
                            updateUser: null,
                            id: '1602917085405552641',
                            templateId: null,
                            name: '文件傳閱單',
                            title: '1111111',
                            agency: null,
                            documentNumber: '1111111',
                            deviseOpinion: null,
                            handResult: null,
                            content: null,
                            summary: null,
                            userId: null,
                            corpId: null,
                            documentType: 1,
                            urgencyDegree: null,
                            secrecyDegree: null,
                            draftId:
                                '[{"userId":"woJe-yBwAArHQYIhObVXpiGU-sKrnLlw","corpId":"wpJe-yBwAAUnMGs7fstrz2mKEx7ql4Kw","type":"userName"}]',
                            draftCompany: null,
                            tellPhone: null,
                            serialNumber: null,
                            outDraftCompany: null,
                            outDraftDesc: null,
                            meetCompany: null,
                            meetDesc: null,
                            checkIds: null,
                            mainIds: null,
                            sendIds: null,
                            acceptTime: null,
                            approvalStatus: 2,
                            recipientStatus: null,
                            nooseStatus: null,
                            receiverCompany: null,
                            acceptStatus: 0,
                            approvalFlag: null,
                            status: null,
                            documentNodeList: null,
                            documentFileList: null,
                            mainFile: null,
                            approvalId: null,
                            nodeId: null,
                            acceptList: null,
                            templateName: null,
                        },
                        {
                            createTime: '2022-11-29 10:08:34.000',
                            createUser: null,
                            updateTime: null,
                            updateUser: null,
                            id: '1597412166783246338',
                            templateId: null,
                            name: '國務院的發文稿紙013',
                            title: '國務院關於發布《國家行政機關公文處理辦法》的通知013',
                            agency: null,
                            documentNumber: '國發〔2022〕013號',
                            deviseOpinion: null,
                            handResult: null,
                            content: null,
                            summary: null,
                            userId: null,
                            corpId: null,
                            documentType: 2,
                            urgencyDegree: 1,
                            secrecyDegree: 3,
                            draftId:
                                '[{"userId":"woJe-yBwAAjhZnLcjG1ZCOX6w_XBBRew","corpId":"wpJe-yBwAAUnMGs7fstrz2mKEx7ql4Kw","type":"userName"}]',
                            draftCompany: null,
                            tellPhone: null,
                            serialNumber: null,
                            outDraftCompany: null,
                            outDraftDesc: null,
                            meetCompany: null,
                            meetDesc: null,
                            checkIds: null,
                            mainIds: null,
                            sendIds: null,
                            acceptTime: null,
                            approvalStatus: 2,
                            recipientStatus: null,
                            nooseStatus: null,
                            receiverCompany: null,
                            acceptStatus: 0,
                            approvalFlag: null,
                            status: null,
                            documentNodeList: null,
                            documentFileList: null,
                            mainFile: null,
                            approvalId: null,
                            nodeId: null,
                            acceptList: null,
                            templateName: null,
                        },
                        {
                            createTime: '2022-11-29 09:42:10.000',
                            createUser: null,
                            updateTime: null,
                            updateUser: null,
                            id: '1597405522967961601',
                            templateId: null,
                            name: '發文稿紙',
                            title: '國務院關於發布《國家行政機關公文處理辦法》的通知010',
                            agency: null,
                            documentNumber: '國務院的發文稿紙010',
                            deviseOpinion: null,
                            handResult: null,
                            content: null,
                            summary: null,
                            userId: null,
                            corpId: null,
                            documentType: 2,
                            urgencyDegree: 1,
                            secrecyDegree: 1,
                            draftId:
                                '[{"userId":"woJe-yBwAAjhZnLcjG1ZCOX6w_XBBRew","corpId":"wpJe-yBwAAUnMGs7fstrz2mKEx7ql4Kw","type":"userName"}]',
                            draftCompany: null,
                            tellPhone: null,
                            serialNumber: null,
                            outDraftCompany: null,
                            outDraftDesc: null,
                            meetCompany: null,
                            meetDesc: null,
                            checkIds: null,
                            mainIds: null,
                            sendIds: null,
                            acceptTime: null,
                            approvalStatus: 2,
                            recipientStatus: null,
                            nooseStatus: null,
                            receiverCompany: null,
                            acceptStatus: 0,
                            approvalFlag: null,
                            status: null,
                            documentNodeList: null,
                            documentFileList: null,
                            mainFile: null,
                            approvalId: null,
                            nodeId: null,
                            acceptList: null,
                            templateName: null,
                        },
                        {
                            createTime: '2022-11-28 17:56:32.000',
                            createUser: null,
                            updateTime: null,
                            updateUser: null,
                            id: '1597167547633934338',
                            templateId: null,
                            name: '發文稿紙12',
                            title: '阿薩德',
                            agency: null,
                            documentNumber: '阿薩德',
                            deviseOpinion: null,
                            handResult: null,
                            content: null,
                            summary: null,
                            userId: null,
                            corpId: null,
                            documentType: 2,
                            urgencyDegree: 2,
                            secrecyDegree: 3,
                            draftId:
                                '[{"userId":"woJe-yBwAAjhZnLcjG1ZCOX6w_XBBRew","corpId":"wpJe-yBwAAUnMGs7fstrz2mKEx7ql4Kw","type":"userName"}]',
                            draftCompany: null,
                            tellPhone: null,
                            serialNumber: null,
                            outDraftCompany: null,
                            outDraftDesc: null,
                            meetCompany: null,
                            meetDesc: null,
                            checkIds: null,
                            mainIds: null,
                            sendIds: null,
                            acceptTime: null,
                            approvalStatus: 2,
                            recipientStatus: null,
                            nooseStatus: null,
                            receiverCompany: null,
                            acceptStatus: 0,
                            approvalFlag: null,
                            status: null,
                            documentNodeList: null,
                            documentFileList: null,
                            mainFile: null,
                            approvalId: null,
                            nodeId: null,
                            acceptList: null,
                            templateName: null,
                        },
                        {
                            createTime: '2022-11-28 14:54:47.000',
                            createUser: null,
                            updateTime: null,
                            updateUser: null,
                            id: '1597121808962723842',
                            templateId: null,
                            name: '文件傳閱單',
                            title: null,
                            agency: null,
                            documentNumber: '611111111111',
                            deviseOpinion: null,
                            handResult: null,
                            content: null,
                            summary: null,
                            userId: null,
                            corpId: null,
                            documentType: 1,
                            urgencyDegree: null,
                            secrecyDegree: null,
                            draftId:
                                '[{"userId":"woJe-yBwAArHQYIhObVXpiGU-sKrnLlw","corpId":"wpJe-yBwAAUnMGs7fstrz2mKEx7ql4Kw","type":"userName"}]',
                            draftCompany: null,
                            tellPhone: null,
                            serialNumber: null,
                            outDraftCompany: null,
                            outDraftDesc: null,
                            meetCompany: null,
                            meetDesc: null,
                            checkIds: null,
                            mainIds: null,
                            sendIds: null,
                            acceptTime: null,
                            approvalStatus: 2,
                            recipientStatus: null,
                            nooseStatus: null,
                            receiverCompany: null,
                            acceptStatus: 0,
                            approvalFlag: null,
                            status: null,
                            documentNodeList: null,
                            documentFileList: null,
                            mainFile: null,
                            approvalId: null,
                            nodeId: null,
                            acceptList: null,
                            templateName: null,
                        },
                        {
                            createTime: '2022-11-28 10:03:16.000',
                            createUser: null,
                            updateTime: null,
                            updateUser: null,
                            id: '1597048447767191553',
                            templateId: null,
                            name: '發文稿紙112',
                            title: '娃哈哈',
                            agency: null,
                            documentNumber: '【2002】88號',
                            deviseOpinion: null,
                            handResult: null,
                            content: null,
                            summary: null,
                            userId: null,
                            corpId: null,
                            documentType: 2,
                            urgencyDegree: 1,
                            secrecyDegree: 3,
                            draftId:
                                '[{"userId":"woJe-yBwAAjhZnLcjG1ZCOX6w_XBBRew","corpId":"wpJe-yBwAAUnMGs7fstrz2mKEx7ql4Kw","type":"userName"}]',
                            draftCompany: null,
                            tellPhone: null,
                            serialNumber: null,
                            outDraftCompany: null,
                            outDraftDesc: null,
                            meetCompany: null,
                            meetDesc: null,
                            checkIds: null,
                            mainIds: null,
                            sendIds: null,
                            acceptTime: null,
                            approvalStatus: 2,
                            recipientStatus: null,
                            nooseStatus: null,
                            receiverCompany: null,
                            acceptStatus: 0,
                            approvalFlag: null,
                            status: null,
                            documentNodeList: null,
                            documentFileList: null,
                            mainFile: null,
                            approvalId: null,
                            nodeId: null,
                            acceptList: null,
                            templateName: null,
                        },
                        {
                            createTime: '2022-11-28 09:46:15.000',
                            createUser: null,
                            updateTime: null,
                            updateUser: null,
                            id: '1597044164443283458',
                            templateId: null,
                            name: '發文稿紙',
                            title: '333333333333',
                            agency: null,
                            documentNumber: '333333333',
                            deviseOpinion: null,
                            handResult: null,
                            content: null,
                            summary: null,
                            userId: null,
                            corpId: null,
                            documentType: 2,
                            urgencyDegree: 2,
                            secrecyDegree: 4,
                            draftId:
                                '[{"userId":"woJe-yBwAArHQYIhObVXpiGU-sKrnLlw","corpId":"wpJe-yBwAAUnMGs7fstrz2mKEx7ql4Kw","type":"userName"}]',
                            draftCompany: null,
                            tellPhone: null,
                            serialNumber: null,
                            outDraftCompany: null,
                            outDraftDesc: null,
                            meetCompany: null,
                            meetDesc: null,
                            checkIds: null,
                            mainIds: null,
                            sendIds: null,
                            acceptTime: null,
                            approvalStatus: 2,
                            recipientStatus: null,
                            nooseStatus: null,
                            receiverCompany: null,
                            acceptStatus: 0,
                            approvalFlag: null,
                            status: null,
                            documentNodeList: null,
                            documentFileList: null,
                            mainFile: null,
                            approvalId: null,
                            nodeId: null,
                            acceptList: null,
                            templateName: null,
                        },
                        {
                            createTime: '2022-11-25 18:22:53.000',
                            createUser: null,
                            updateTime: null,
                            updateUser: null,
                            id: '1596087013616922626',
                            templateId: null,
                            name: '發文稿紙',
                            title: '阿薩大時代',
                            agency: null,
                            documentNumber: '啊實打實',
                            deviseOpinion: null,
                            handResult: null,
                            content: null,
                            summary: null,
                            userId: null,
                            corpId: null,
                            documentType: 3,
                            urgencyDegree: 2,
                            secrecyDegree: 3,
                            draftId:
                                '[{"userId":"woJe-yBwAAjhZnLcjG1ZCOX6w_XBBRew","corpId":"wpJe-yBwAAUnMGs7fstrz2mKEx7ql4Kw","type":"userName"}]',
                            draftCompany: null,
                            tellPhone: null,
                            serialNumber: null,
                            outDraftCompany: null,
                            outDraftDesc: null,
                            meetCompany: null,
                            meetDesc: null,
                            checkIds: null,
                            mainIds: null,
                            sendIds: null,
                            acceptTime: null,
                            approvalStatus: 2,
                            recipientStatus: null,
                            nooseStatus: null,
                            receiverCompany: null,
                            acceptStatus: 0,
                            approvalFlag: null,
                            status: null,
                            documentNodeList: null,
                            documentFileList: null,
                            mainFile: null,
                            approvalId: null,
                            nodeId: null,
                            acceptList: null,
                            templateName: null,
                        },
                    ]
                })
                .finally(() => {
                    this.loading = false
                    setTimeout(() => {
                        this.isRefresh = false
                    }, 1000)
                })
        },
        getBillAuditList() {
            const formatDraftId = (id) => {
                if (!id) return []
                return JSON.parse(id)
            }
            documentBillAuditPage(
                Object.assign(
                    {
                        pageNo: this.pageNo,
                        pageSize: this.pageSize,
                    },
                    this.queryParam
                )
            )
                .then((res) => {
                    const data = { ...res.data }
                    data.rows = data.rows.map((ele) => {
                        ele.draftId = formatDraftId(ele.draftId)
                        return ele
                    })
                    this.finished = data.rows && data.rows.length < this.pageSize
                    if (this.pageNo == 1) {
                        this.billAuditList = [...data.rows]
                    } else {
                        this.billAuditList = [...this.billAuditList, ...data.rows]
                    }
                    this.$set(this, 'list', this.billAuditList)
                    // this.list = this.billAuditList
                })
                .finally(() => {
                    this.loading = false
                    setTimeout(() => {
                        this.isRefresh = false
                    }, 1000)
                })
        },
    },
}
